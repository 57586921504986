@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/Roboto/Roboto-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/Roboto/Roboto-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/Roboto/Roboto-Bold.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Roboto";
  src: url("assets/Roboto/Roboto-Black.ttf");
  font-weight: 700;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100%;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-thumb {
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #9ca3af;
}

::-webkit-scrollbar-thumb:hover {
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #6b7280;
}*/

input:focus::placeholder {
  color: transparent;
}
