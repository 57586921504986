.react-datepicker {
  font-family: inherit;
  border: 1px solid #d1d5db;
}

.react-datepicker__header {
  background: white;
  border-bottom: 1px solid #d1d5db;
}

.react-datepicker__day-name {
  font-weight: 400;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #3b82f6 !important;
}
